/* MP: Mover los mensajes de error de la derecha a la izquierda en los formularios*/
.u-has-error-v1-3{
  .form-control-feedback{
    &:before{
      right: 0;
      left: 6px;
    }
  }
}
/* MP Solucionar el cerrado de los modales */
.close{
  position: relative;
  z-index: 10000;
}