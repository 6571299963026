
//Ruta de las fuentes de font-awesome
$fa-font-path: "/build/webfonts";

/** Importar font awesome */
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';

/** Importar colecciones font awesome */
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';