.petstravelblog {

  blockquote {

    font-family: Roboto, serif;
    font-size: 16px;
    font-style: italic;
    margin: 0.5em 0;
    padding: 1em 40px;
    position: relative;
    border-left:5px solid #e40050; /* Color principal */
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    max-width: 80%;
    width: 100%;
    @extend .g-color-primary;

    p {
      margin: 0px;
    }
  }

  blockquote cite {
    color: #efefef;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  blockquote cite:before {
    content: "\2014 \2009";
  }

  h2{
    font-family: "Roboto Slab", serif !important;
    @extend .g-color-black;
    @extend .g-pt-20;
    @extend .g-pb-10;
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    max-width: 80%;
    width: 100%;
  }

  h3 {
    font-family: "Roboto Slab", serif !important;
    @extend .g-color-black;
    @extend .g-pt-10;
    @extend .g-pb-5;
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    max-width: 80%;
    width: 100%;
  }

  h4 {
    font-family: "Roboto Slab", serif !important;
    @extend .g-color-black;
    @extend .g-pt-10;
    @extend .g-pb-0;
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    max-width: 80%;
    width: 100%;
  }

  p{
    text-align: justify;
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    max-width: 80%;
    width: 100%;
  }

  figure{
    margin-top: 40px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }


  img {
    height: auto !important;
    border-radius: 5px !important;
  }

  @media (min-width: $g-md) {
    img {
      //Centrado de imagen
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: $g-md){
      img {
      width: 100% !important;
    }
  }


  figcaption {
    @extend .g-color-black;
    text-align: center;
    font-style: italic;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  figcaption::before {
    content: "- ";
    @extend .g-color-primary;
    font-weight: bold;
  }

  figcaption::after {
    content: " -";
    @extend .g-color-primary;
    font-weight: bold;
  }

  ul {
    text-align: justify;
    max-width: 80%;
    width: 100%;
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    padding-left: 25px;
  }

  ol {
    text-align: justify;
    //width: -moz-available !important;          /* WebKit-based browsers will ignore this. */
    //width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    //width: fill-available !important;
    max-width: 80%;
    width: 100%;
    padding-left: 25px;
  }

  li {
    padding-bottom: 10px;
  }
}

#commentsubmit {
  @extend .btn;
  @extend .u-btn-primary;
  @extend .g-font-weight-600;
  @extend .g-font-size-13;
  @extend .g-rounded-25;
  @extend .g-py-15;
  @extend .g-px-30;
  margin-left: 125px;
  text-transform: uppercase;
}
