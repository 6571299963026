@import "colores.scss";
@import "map";
@import "buttons";
@import "animations";
@import "review_stars";
@import "forms";

//Ñapa para evitar cosas feas en el slider del tamaño de perro de la home
.rc-slider-mark-text{
  user-select: none;
}

//Ñapa para ocultar el badge del recaptcha
.grecaptcha-badge { z-index: 13; }

//Centrar el carrusel de imagenes
.slick-track{
  display: inline-block;
  img {
    margin: auto;
  }
}

@font-face {
	font-family: "Slow Life";
	src: url("/build/webfonts/SlowLife.ttf");
}

.slow-life {
	font-family: 'Slow Life' !important;
	font-weight: normal;
	font-style: normal;
}

.br-3 {	
	border-radius: 3px;		
}

#navBar > ul > li.nav-item > div > a{
	border-bottom: 1px solid #ccc;
}

.petfriendly .ficha {
    background-color: #f1f1f1;
    border-radius: 5px;
    box-shadow: 5px 5px 11px 2px #dbdbdb;;
}
.petfriendly .ficha img{
    border-radius: 6px 6px 0 0;
}

.petfriendly .ficha .interior-ficha{
	padding: 15px;
}

.petfriendly h3 {
	line-height: 0.4;
	text-align:left;
}

.slick-slider .slick-slider .slick-arrow {
    display: none !important;
}
.slick-slider .slick-slider:hover > .slick-arrow {
    display: block !important; 
}

.u-heading-v2-3--bottom:after {
	border-color: lightgray;
	width: 8rem;
}

@media (min-width: 576px) {	
	.home-title {
		font-size: 12rem;
		color:white;
		font-family: 'Slow Life' !important;
		line-height: 0.5;
		max-width: 400px;
	}
}
@media (min-width: 768px) {
	.searcher-box .search-btn{
		border-radius: 0px 14px 14px 0px;
	}
}

@media (max-width: 576px) {	
	.home-title {
		font-size: 6rem;
		color:white;
		font-family: 'Slow Life' !important;
		line-height: 0.5;
	}
}
@media (max-width: 768px) {
	.searcher-box .search-btn{
		border-radius: 14px;
	}
}
.g-bottom-70 {
  bottom: 5rem;
}
.mb-25px {
    margin-bottom: 25px!important;
}
.u-select-v2 .chosen-drop {
    width: calc(100% + 90px);
}

.search-label{
	width:100%;
	padding-left:10px;
	margin-bottom: 0;
}

.searcher-box{
    background-color: white;
    border-radius: 20px;
}

.searcher-box .search-btn{
    height: 92%;
    padding: 0;
}

.searcher-box input{
	min-width: 20px;
}

#searcherIndex .searcher-box > div > div {
    border-right: 1px solid lightgray;
}

.searcher-icon {
	    padding: 5% 0 5% 10%;
}
.searcher-icon img{
	max-width: 30px;
    max-height: 28px;
    position: absolute;
    bottom: 18px;
    right: -12px;
}


.display-table{
	display: table;
}
.table-cell-center{
	display: table-cell;
    vertical-align: middle;
}

@media (min-width: $g-lg) {
  .g-max-width-400--lg {
    max-width: 400px;
  }
  .g-max-width-500--lg {
    max-width: 500px;
  }
}

@media (min-width: 576px) {

  .g-font-size-60--md {
    font-size: 4.2857142857rem !important; }
}

//Borde imagenes ppp
.g-border-crema {
  border: #F8F6F3;
  border-width: 10px;
  border-style: solid;
}

.g-height-430 {
  height: 430px !important;
}

.g-max-width-130 {
  max-width: 130px !important;
}

@media (min-width: 576px) {
  .g-height-400--sm {
    height: 400px !important;
  }
}

//Posicioner imagen vectorial home-portada
.g-left-250 {
  @include px-to-rem(left, 250px);
}

// Titulares
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab", serif !important;
}

.font-family-roboto {
  font-family: "Roboto Slab", serif !important;
}

.font-family-roboto-sans {
  font-family: "Roboto", serif !important;
}

// Posicionamiento izquierdo para talla de perro admitida en alojamiento
.g-left-25 {
  left: 1.78571428575rem !important;
}

.g-width-850 { /* RG-Q */
  width: 850px !important; /* P */
  max-width: 100%;
}

.g-height-50-important {
  height: 50px !important;
}

// Posicionamiento horizontal ilustracion home
.g-left-400 {
  left: 400px;
}

// Posicionamiento vertical ilustracion home
.g-top-95 {
  top: 95px;
}

.g-top-130 {
  top: 130px;
}

@media (min-width: 768px) {
.g-top-13--md {
  top: 13px;
  }
}

// Color de fondo para botones redes sociales con comportamiento igual al de los botones
.g-bg-secondary--hover:hover {
  background-color: #ff1869 !important;
}

// Color de fondo crema
.g-bg-crema {
  background-color: $color-crema !important;
}

// Color de fondo morado
.g-bg-morado {
  background-color: $color-morado !important;
}

// Color de fondo cielo
.g-bg-cielo {
  background-color: $color-cielo !important;
}

// Color de fondo huevo
.g-bg-huevo {
  background-color: $color-huevo !important;
}

// Color de fondo footer 191919
.g-bg-footer {
    background-color: #191919 !important;
}

// Color de fondo relleno
.g-bg-relleno {
  background-color: #111111 !important;
}

// Color de fondo verde
.g-bg-verde {
  background-color: $color-verde !important;
}

// Color de fondo rojo
.g-bg-rojo {
  background-color: $color-rojo !important;
}

// Color de fondo morado fuerte
.g-bg-moradofuerte {
  background-color: darken($g-color-primary,20%);
}

// Color de fondo alerta ok
.g-bg-alerta-ok {
  background-color: $color-alerta-ok !important;
}

// Color de fondo alerta error
.g-bg-alerta-error {
  background-color: $color-alerta-error !important;
}

// Color de fondo alerta warn
.g-bg-alerta-warn {
  background-color: $color-alerta-warn !important;
}

// Color de fondo alerta info
.g-bg-alerta-info {
  background-color: $color-alerta-info !important;
}

.oleo-font{
	font-family: 'Oleo Script', cursive !important;
}
// Color blanco
.g-color-blanco {
  color: #FFFFFF !important;
}

// Color morado
.g-color-morado {
  color: $color-morado !important;
}

// Color amarillo
.g-color-amarillo {
  color: $color-huevo !important;
}

// Color verde
.g-color-verde {
  color: $color-verde !important;
}

// Color verde
.g-color-cielo {
  color: $color-cielo !important;
}

//Displays
.g-d-inline{
  display: inline;
}

.g-d-inline-block{
  display: inline-block;
}

/*------------------------------------
  Tabs vP
------------------------------------*/
[class*="u-nav-vp"] {

  /*---------------------------------------------------
     Estado de la pestaña en espera
    ---------------------------------------------------*/

  .nav-link {
    position: relative;
    min-height: 100%;
    padding: 10px 200px;
    background-color: $g-color-white;
    color: $g-color-black;
    transition: none;

    &::after {

    /*{content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100%;
      background: {
        image: linear-gradient(to left, $g-color-primary 0%, rgba($g-color-gray-dark-v1, .2) 100%);
        repeat: repeat-y;
      }
      z-index: 1;
      }*/
    }

    /*---------------------------------------------------
     Estado de la pestaña clickado
    ---------------------------------------------------*/

    &.active {
      background-color: $g-color-primary;
      color: $g-color-white;

      &::after {
        display: none;
      }

      .u-nav-vp__icon {
        background-color: $g-color-primary;
      }

      .u-nav-vp__description {
        color: rgba($g-color-white, .7);
      }
    }
  }

  &.u-nav-light {
    .nav-link {
      color: $g-color-gray-dark-v2;

      &.active {
        color: $g-color-main;
        background-color: $g-color-white;

        .u-nav-vp {
          &__icon {
            color: $g-color-white;
            background-color: $g-color-primary;
          }

          &__title,
          &__description {
            color: $g-color-main;
          }
        }
      }
    }

    .u-nav-vp {
      &__icon {
        background-color: $g-color-white;
        color: $g-color-white;
      }

      &__title,
      &__description {
        color: $g-color-white;
      }
    }
  }
}

.u-nav-vp {
  &__icon,
  &__title,
  &__description {
    position: relative;
    z-index: 3;
  }

  &__icon {
    display: none;
    background-color: $g-color-gray-dark-v2;
    color: $g-color-white;
    /*transform: translateY(-51%);*/
    margin-top: -35px;
    margin-bottom: 6px;
    transition: none;
  }

  &__title {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
  }

  &__description {
    color: inherit;
    font-style: normal;
  }
}


@media (min-width: $g-md) {
  [class*="u-nav-vp"] {
    .nav-link {
      padding: 0 20px 10px;
    }

    &.u-nav-light {
      .nav-link {
        background-color: $g-color-white;

        &::before {
          background-image: linear-gradient(to bottom left, transparent 49.6%, $g-color-white 50%), linear-gradient(to top left, transparent 49.6%, $g-color-white 50%);
        }

        &::after {
          background-image: linear-gradient(to left, $g-color-white 0%, rgba($g-color-gray-light-v2, .2) 100%);
        }

        &.active {
          color: $g-color-white;
          background-color: $g-color-primary;

          &::before {
            background-image: linear-gradient(to bottom left, transparent 49.6%, $g-color-primary 50%), linear-gradient(to top left, transparent 49.6%, $g-color-primary 50%);
          }

          .u-nav-vp {
            &__title {
              color: $g-color-white;
            }

            &__description {
              color: rgba($g-color-green, .7);
            }
          }
        }
      }

      .u-nav-vp {
        &__icon,
        &__title,
        &__description {
          color: $g-color-main;
        }
      }
    }

    &.justify-content-end,
    &.text-right {
      .nav-item {
        &:first-child {
          .nav-link {
            &::before {
              display: none;
            }
          }
        }

        &:last-child {
          .nav-link {
            padding: 0 20px 25px;

            &::before {
              display: block;
            }
          }
        }
      }

      .nav-link {
        &::before {
          left: -26px;
          right: auto;
          background: {
            position: top right, bottom left;
            image: linear-gradient(to bottom right, transparent 49.6%, $g-color-gray-dark-v2 50%), linear-gradient(to top right, transparent 49.6%, $g-color-gray-dark-v2 50%);
          }
        }

        &::after {
          left: auto;
          right: 0;
          background-image: linear-gradient(to right, $g-color-gray-dark-v2 0%, rgba($g-color-gray-dark-v1, .2) 100%);
        }

        &.active {
          &::before {
            background-image: linear-gradient(to bottom right, transparent 49.6%, $g-color-primary 50%), linear-gradient(to top right, transparent 49.6%, $g-color-primary 50%);
          }
        }
      }
    }

    &.flex-column {
      .nav-item {
        margin-bottom: 1px;

        &:first-child {
          .nav-link {
            padding: 20px 20px 20px 50px;
          }
        }

        &:last-child {
          .nav-link {
            &::before {
              display: block;
            }
          }
        }
      }

      .nav-link {
        padding: 20px 20px 20px 50px;

        &::before {
          opacity: 0;
        }

        &.active {
          &::before {
            opacity: 1;
          }
        }
      }

      .u-nav-vp {
        &__icon {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }

  .u-nav-vp {
    &__icon {
      display: inline-block;
    }

    &__description {
      color: rgba($g-color-black, .5);
    }
  }

  .u-nav-vp-2 {
    .nav-item {
      &:first-child {
        .nav-link {
          padding: 0 20px 25px;
        }
      }

      &:last-child {
        .nav-link {
          &::before {
            display: none;
          }
        }
      }
    }

    .nav-link {
      padding: 0 20px 25px 50px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -26px;
        display: block;
        width: 26px;
        height: 100%;
        background: {
          repeat: no-repeat;
          size: 100% 50.8%;
          position: top right, bottom left;
          image: linear-gradient(to bottom left, transparent 49.6%, $g-color-gray-dark-v2 50%), linear-gradient(to top left, transparent 49.6%, $g-color-gray-dark-v2 50%);
        }
        z-index: 2;
      }

      &.active {
        &::before {
          background-image: linear-gradient(to bottom left, transparent 49.6%, $g-color-primary 50%), linear-gradient(to top left, transparent 49.6%, $g-color-primary 50%);
        }
      }
    }

    &.justify-content-end,
    &.text-right {
      .nav-item {
        &:first-child {
          .nav-link {
            padding: 0 50px 25px 20px;
          }
        }
      }

      .nav-link {
        padding: 0 50px 25px 20px;
      }
    }
  }
}

/*------------------------------------
  Checkboxes vp
------------------------------------*/

.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // vp
      &-vp {
        cursor: pointer;
        display: block;
        width: 43px;
        height: 22px;
        font: {
          style: normal;
          weight: 700;
          size: 9px;
        }
        color: $g-color-white;
        border: solid 1px $g-color-white;
        border-radius: 12px;

        i {
          &::before,
          &::after {
            content: "";
            display: block;
            position: absolute;
          }

          &::before {
            content: attr(data-uncheck-icon);
            top: 0;
            left: 0;
            width: 100%;
            height: 22px;
            line-height: 18px;
            text-transform: uppercase;
            text-align: right;
            padding: 2px 7px;
          }

          &::after {
          /*top: 50%;*/
            left: 4px;
            width: 16px;
            height: 16px;
            background-color: $g-color-white;
            border-radius: 50%;
            transform: translateY(-50%);
            transition: {
              property: left;
              duration: .1s;
              timing-function: ease-in;
            }
          }
        }
      }
      // End vp
    }
  }

  // Control
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      & +,
      & + * {
        // vp
        .u-check-icon-checkbox-vp,
        .u-check-icon-radio-vp {
          color: $g-color-white;
          background-color: $g-color-primary;
          border-color: $g-color-primary;

          i {
            &:before {
              content: attr(data-check-icon);
              text-align: left;
            }

            &::after {
              left: calc(100% - 19px);
              background-color: $g-color-white;
            }
          }
        }
        // End vp
      }
    }
  }
}

.btn.disabled, .btn:disabled {
  opacity: .25;
  cursor: not-allowed;
}

.input-sin-focus{
  outline: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}

.degradado_cielo {
  background: rgba(109,39,84,1);
  background: -moz-linear-gradient(45deg, rgba(132,173,175,1) 0%, rgba(212,227,229,1) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(132,173,175,1)), color-stop(100%, rgba(212,227,229,1)));
  background: -webkit-linear-gradient(45deg, rgba(132,173,175,1) 0%, rgba(212,227,229,1) 100%);
  background: -o-linear-gradient(45deg, rgba(132,173,175,1) 0%, rgba(212,227,229,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(132,173,175,1) 0%, rgba(212,227,229,1) 100%);
  background: linear-gradient(45deg, rgba(132,173,175,1) 0%, rgba(212,227,229,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84adaf', endColorstr='#d4e3e5', GradientType=1 );
}

.ui-slider-range {
  background: #e40050 !important;
}

.icon-slide-left {
  vertical-align: middle;
  padding-left: 10px;
}

.icon-slide-right {
  vertical-align: middle;
  padding-right: 10px;
}
.remember-input {
  padding: 0px;
  margin: 0px;
  width: 40px;
  margin-top: 7px;
  padding-left: 5px;
  font-size: 12px;
}