/** Animación react slides desplegables listado*/

.slide-enter {
  max-height: 0px;
  -webkit-transition: max-height 1s ease;
  overflow: hidden; }

.slide-enter.slide-enter-active {
  //height: auto;
  max-height: 500px; }

.slide-leave {
  //max-height: 500px;
  -webkit-transition: height 1s ease; }

.slide-leave.slide-leave-active {
  overflow: hidden;
  max-height: 0px; }