
$md-map-open: 300px;
$md-map-close: 80px;

$lg-map-open: 400px;
$lg-map-close: 100px;

$sm-map: 75px;

//Mapa anclado al menu
.list-map-frame{
  &-fixed {
    position: fixed;
    width: 100%;
    z-index: 25;
    height: $sm-map;

    @media (min-width: $g-md) {
      height: $md-map-close;
    }

    @media (min-width: $g-lg) {
      height: $lg-map-close;
    }
  }

  &-static{
    height: $sm-map;

    @media (min-width: $g-md) {
      height: $md-map-open;
    }

    @media (min-width: $g-lg) {
      height: $lg-map-open;
    }
  }

  &-open-button{

    @media screen and (min-width: $g-md){
      display: none;
    }

    transform: translateY(-50%);
    display: block;
    position: relative;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    bottom: 35px;
    z-index: 3;
    text-align: center;
    vertical-align: middle;
  }

  &-close-button{

    @media screen and (min-width: $g-md){
      display: none;
    }

    transform: translateY(-50%);
    display: block;
    position: relative;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    bottom: 60px;
    z-index: 3;
    text-align: center;
    vertical-align: middle;
  }
}




.list-map-container{
  &-static{
    //Quitamos un poco de padding a las pestañas para hacerlas mas pequeñas (Version escritorio)
    @media (min-width: $g-md){
      [class*="u-nav-v8"] {
        .nav-link {
          padding: 0 10px 6px;
        }
      }
    }
  }
  &-fixed{
    //Aire entre mapa y pestañas en version movil
    padding-top: $sm-map;

    @media (min-width: $g-md) {
      padding-top: 200 + $md-map-close;
    }

    @media (min-width: $g-lg) {
      padding-top:  200 + $lg-map-close;
    }

    //En version tablet y desktop ocultamos las pestañas al hacer scroll
    @media (min-width: $g-md) {
      .u-nav-v8-1 {
        display: none;
      }
    }
  }
}


.businessMarker {
  margin-bottom: 10px;
  padding-bottom: 15px;
  background-color: white;
  border-radius: 6px;
  width: 250px;

  @media (max-width: $g-sm) {
    width: 200px;
    .h4{
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &:after{
    content : " ";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    position:absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mapcontrol{
  .btn{
    transform: translateY(-100%);
  }
}
