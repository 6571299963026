$color-principal: #e40050;
$color-crema: #FCFBF9;
$color-morado: #6f2b53; //#6F2956
$color-cielo: #D3E1E4;
$color-huevo: #F9AE13;//#F8AF07
$color-verde: #3EAB37;
$color-rojo: #E20C17;


$color-amarillo: #FFAF00;
$color-naranja: #FF8246;
$color-principal: #e40050;
$color-verde: #00A54B;
$color-azul: #50B4C8;
$color-anil: #506496;
$color-morado: #6E2855;
$color-pizarra: #1E1E1E;
$color-cielo: #D4E3E5;

$color-alerta-ok: #3aaa35;
$color-alerta-error: #e30613;
$color-alerta-warn: #f8a800;
$color-alerta-info: #00b6ed;











